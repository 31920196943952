@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
header {
  transition: all 0.3s ease-in-out;
  box-shadow: 0 1px 6px 0 rgb(123 123 123);
  box-shadow: 0 2px 6px rgba(0, 0, 0, .2);
  top: 0px;
  background-color: #ffffff8c;
    position: fixed;
    z-index: 40;
    height: auto;
    width: 100%;
}
.logo {
  padding: 10px 0px;
}
.logo img {
  transition: width .3s ease;
}
.logo a {
  color: #3a76cb;
  text-transform: capitalize;
  color: #000000;
  font-size: 35px;
  font-weight: 500;
  text-decoration: none;
}
.main-nav ul {
    list-style: none;
    padding-left: 0px;
    margin-bottom: 0px;
}
.main-nav li {
  position: relative;
    display: inline-block;
    margin: 12px 20px;
}
.main-nav li a{
  position: relative;
}
.main-nav li a::before{
  content: '';
  width: 0;
  height: 2px;
  background-color: #3a76cb;
  position: absolute;
  bottom: -3px;
  left: 0;
  z-index: 1;
  transition: width 0.3s ease 0s
}
.main-nav li a:hover::before{
  width: 100%;
}
.main-bg {
  background-color: #F2F6FC;
}
table th {
  white-space: nowrap;
  border-right: 1px solid;
}
table tr {
  white-space: nowrap;
}
.statusBtn {
  background: #55e175;
}
.statusBtn, .statusInactive {
  border-radius: 14px;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  padding: 3px 10px;
}
.statusInactive {
  background: #ed3c39;
}
.postback-modal:before {
  background: #000;
  content: "";
  height: 100%;
  left: 0;
  opacity: .7;
  position: absolute;
  top: 0;
  width: 100%;
}